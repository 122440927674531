import { OBSERVER } from './../main.js'
import { Accordions } from '../classes/accordions.js'
import Overlay from '../vendor/overlay.js'
import ShareButtons from 'share-buttons/dist/share-buttons.js'

// Fonction gérant l'overlay menu desktop
export const overlayDropdown = () => {

  let dropdown = new Overlay({
    name: 'dropdown',
    create: { close: false },
    click: { buttons: { trigger: '.js-trigger-overlay-dropdown', close: '.js-close-all-overlay, .js-close-overlay-dropdown, .overlay-dropdown__background, .js-header-logo', switch: '.js-toggle-overlay-search' }},
    options: {
      speed: 800
    }
  })

  dropdown.init()
}


// Fonction gérant l'overlay Demande en ligne
export function overlayQuote() {
  let quote = new Overlay({
    name: 'quote',
    create: { close: false },
    events:  { openComplete: true },
    click: { buttons: { toggle: '.js-toggle-overlay-quote', switch: '.js-close-all-overlay, .js-toggle-overlay-menu, .js-trigger-overlay-dropdown, .js-toggle-overlay-fastlinks', close: '.js-close-overlay-quote' }},
    options: {
      speed: 800,
      goToSelector: 'html, body',
    }
  })

  quote.init()
}


// Fonction gérant l'overlay de menu mobile
export function overlayMenu() {
  let menu = new Overlay({
    name: 'menu',
    create: { close: false },
    events:  { openComplete: true },
    click: { buttons: { toggle: '.js-toggle-overlay-menu', switch: '.js-close-all-overlay, .js-trigger-overlay-dropdown, .js-toggle-overlay-search, .js-toggle-overlay-quote', close: '.js-close-overlay-menu' }},
    options: {
      speed: 800,
      goToSelector: 'html, body',
    }
  })

  menu.init()

  const level1 = new Accordions({
    containerClass: 'js-accordions-menu-level-1',
    accordionClass: 'js-accordion-menu-level-1',
    toggleButtonClass: 'js-accordion-menu-toggle-level-1',
    contentClass: 'js-accordion-menu-content-level-1',
    openedClass: 'js-accordion-menu-opened-level-1',
  })

  const onClose = () => {
    level1.closeAll()
  }
  OBSERVER.add({
    name: 'overlayMenu',
    event: 'onCloseOverlayMenu',
    function: onClose
  })
  OBSERVER.on('overlayMenu')

  function checkActiveSection() {
    var selection = document.querySelector('.js-accordion-menu-level-1.js-accordion-menu-opened-level-1') !== null
    if (selection) {
      document.querySelector('.js-menu').classList.add('has-menu-open')
    } else {
      document.querySelector('.js-menu').classList.remove('has-menu-open')
    }
  }
  OBSERVER.add({
    name: 'checkActiveSection',
    event: 'click',
    target: '.js-accordion-menu-toggle-level-1',
    function: checkActiveSection
  })
  OBSERVER.on('checkActiveSection')
}


// Fonction gérant l'overlay Search
export function overlaySearch() {
  let search = new Overlay({
    name: 'search',
    create: { close: false },
    events:  { openComplete: true },
    click: { buttons: { toggle: '.js-toggle-overlay-search', switch: '.js-close-all-overlay, .js-toggle-overlay-menu, .js-trigger-overlay-dropdown, .js-toggle-overlay-quote', close: '.js-close-overlay-search' }},
    animations: {
      selector: '.js-overlay-search-content',
      addTransition: true,
      styles: [{ property: 'height', value: '100%', easing: 'easeInOutQuart' }]
    },
    options: {
      speed: 800,
      goToSelector: 'html, body',
      closeOnResize: false, // Empêche l'overlay de se fermer tout seul en mobile lorsque le clavier du mobile s'ouvre
    }
  })
  
  const onOpenComplete = () => { searchInput.focus() }

  search.init()

  const searchInput = document.querySelector('.js-input-to-clear')
  OBSERVER.add({ name:'overlaySearch', event: 'onOpenCompleteOverlaySearch', function: onOpenComplete })
  OBSERVER.on('overlaySearch')

  function clearSearchForm() {
    document.querySelector('.js-search-popular').classList.add('visible')
    document.querySelector('.js-search-popular').classList.remove('hidden')
    document.querySelector('.js-search-autocomplete').classList.add('hidden')
    document.querySelector('.js-search-autocomplete').classList.remove('visible')
    search.animate()
  }
  OBSERVER.add({
    name: 'clearSearchForm',
    event: 'click',
    target: '.js-overlay-search-content .js-clear-input',
    function: clearSearchForm
  })
  OBSERVER.on('clearSearchForm')


  activateWithKeyUp('#form-search #form-search-input')

  //Autocomplete search
  function activateWithKeyUp(input, selector) {

    var ajaxRequest = false

    $(input).keyup(function(e) {

      if( ajaxRequest ) {
        // Si une requête est déjà en cours, l'annuler pour faire place à la prochaine
        ajaxRequest.abort()
      }

      var keycode = (e.keyCode ? e.keyCode : e.which)

      if ( keycode != 13 ) {
        if ($(this).val().length >= 4) {

          ajaxRequest = $.request('rechercheAutocomplete::onFetchAutocomplete', {
            data: { q: $(this).val() },
            update: { 'rechercheAutocomplete::autocomplete':'.js-search-autocomplete'},
            complete: function (data) {
              // Results loaded.
              ajaxRequest = false

              // Afficher le bloc d'autocomplétion et cacher les recherches populaires
              document.querySelector('.js-search-popular').classList.add('hidden')
              document.querySelector('.js-search-popular').classList.remove('visible')
              document.querySelector('.js-search-autocomplete').classList.add('visible')
              document.querySelector('.js-search-autocomplete').classList.remove('hidden')
              search.animate()

              function initAutocompleteButton() {
                var searchForm = document.querySelector('#form-search') !== null
                if (searchForm) {
                  document.querySelector('#form-search').submit()
                }
              }
            
              OBSERVER.add({
                  name: 'initAutocompleteButton',
                  event: 'click',
                  target: '.js-trigger-overlay-search-form',
                  function: initAutocompleteButton
              })
              OBSERVER.on('initAutocompleteButton')

            },
          })
        }
        else {
          document.querySelector('.js-search-autocomplete').innerHTML = ""

          // Cacher le bloc d'autocomplétion et afficher les recherchers populaires
          document.querySelector('.js-search-popular').classList.add('visible')
          document.querySelector('.js-search-popular').classList.remove('hidden')
          document.querySelector('.js-search-autocomplete').classList.add('hidden')
          document.querySelector('.js-search-autocomplete').classList.remove('visible')
          search.animate()
        }
      }
    })
  }

}


export function overlayPopup() {
  new Overlay({
    name: 'popup',
    click: {
      buttons: {
        close: '.js-close-overlay-popup',
      }
    },
    timeout: {
      delay: 2000
    },
    options: {
      speed: 600,
    }
  }).init()
}


export function overlayShare() {

  new Overlay({
    name: 'share',
    click: {
      buttons: {
        open: '.js-share',
        close: '.close-overlay',
      }
    },
    options: {
      speed: 800,
    }
  }).init()

  if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
    __sharethis__.init(__sharethis__.config)
  }

  // Message de confirmation de copie de l'URL ----------------
  function confirmClipboardCopy() {
    alert('L’adresse URL a bien été copiée dans votre presse-papier.')
  }
  OBSERVER.add({
    name: 'confirmClipboardCopy',
    event: 'click',
    target: '.js-share-copy',
    function: confirmClipboardCopy
  })
  OBSERVER.on('confirmClipboardCopy')

  // Gestion de l'ouverture de l'overlay ----------------------
  function openShareOverlay(e) {
    if (e.currentTarget.dataset.rubric) {
      window.history.pushState(null, null, '#' + e.currentTarget.dataset.rubric)
    }
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href)
    ShareButtons.update()
  }
  OBSERVER.add({
    name: 'openShareOverlay',
    event: 'click',
    target: '.js-share',
    function: openShareOverlay
  })
  OBSERVER.on('openShareOverlay')

  // Gestion de la fermeture de l'overlay ---------------------
  function closeShareOverlay() {
    window.history.replaceState(null, null, ' ')
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href)
    ShareButtons.update()
  }
  OBSERVER.add({
    name: 'closeShareOverlay',
    event: 'click',
    target: '.overlay-share__background, .overlay-share__close',
    function: closeShareOverlay
  })
  OBSERVER.on('closeShareOverlay')

}