// Documentation: https://swiperjs.com/swiper-api
// Exemple de slider dans dtg-dentistes
// import Swiper from 'swiper/bundle'


// ---------------------------------------------------------------------------
// Importations
// ---------------------------------------------------------------------------
import Swiper from 'swiper/bundle'

export function homeBanners() {

  //document.querySelector('.js-home-banners').style.height = window.innerHeight + 'px'

  // Looper dans chaques slides et réduire le titre si il est plus long que 30 caractères
  let homeSlides = document.querySelectorAll('.js-home-banners-title');
  [].forEach.call(homeSlides, function(slide) {
    if(slide.innerHTML.length > 30) {
      slide.classList.add('home-banners__content-title-smaller')
    }
  })

  // Slider des images
  let homeBannerSlider = new Swiper('.js-home-banners-slides', {
    slidesPerView: 1,
    effect: 'fade',
    loop: true,
    loopedSlides: 0,
    pagination: {
      el: '.js-home-banners-navigation',
      type: 'bullets',
      clickable: true,
    },
    autoplay: {
      delay: 7000,
      disableOnInteraction: false
    },
  })
  homeBannerSlider.init()

}

export function servicesBanners() {

  let servicesSlider = new Swiper('.js-services-slides', {
    loop: true,
    slidesPerView: 'auto',
    spaceBetween: 20,
    navigation: {
      prevEl: '.js-services-slider-prev',
      nextEl: '.js-services-slider-next',
    },
    on: {
      afterInit: function(e){
        let slideNb = this.loopedSlides

        if(slideNb < 2){
          document.querySelector('.js-services-slides-pager').style.display='none'
          document.querySelector('.js-services-slides-arrows').style.display='none'
        }

        if(slideNb < 10){
          slideNb = '0'+this.loopedSlides
        }

        document.querySelector('.js-services-slider-total').innerHTML = slideNb
      },
      slideChange: function(){
        let currentSlide = document.querySelector('.js-services-slider-current')
        let currentIndex = this.realIndex + 1
        if(currentIndex < 10){
          currentIndex = '0'+currentIndex
        }
        currentSlide.innerHTML = (currentIndex)
      }
    },
    breakpoints: {
      768: {
        spaceBetween: 50,
      },
    },
    autoplay: {
      delay: 5000,
    },
  })

  servicesSlider.init()

}

export function advantagesBanners() {

  let advantagesSlider = new Swiper('.js-advantages-slides', {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 50,
    navigation: {
      prevEl: '.js-advantages-slider-prev',
      nextEl: '.js-advantages-slider-next',
    },
    on: {
      afterInit: function(e){
        let slideNb = this.slides.length - this.loopedSlides*2;
        slideNb = this.slides.length - this.loopedSlides*2;

        if(slideNb < 2){
          document.querySelector('.js-advantages-slides-pager').style.display='none'
          document.querySelector('.js-advantages-slides-arrows').style.display='none'
        }

        if(slideNb < 10){
          slideNb = '0'+slideNb
        }

        document.querySelector('.js-advantages-slider-total').innerHTML = slideNb
      },
      slideChange: function(){
        let currentSlide = document.querySelector('.js-advantages-slider-current')
        let currentIndex = this.realIndex + 1
        if(currentIndex < 10){
          currentIndex = '0'+currentIndex
        }
        currentSlide.innerHTML = (currentIndex)
      }
    },
    breakpoints: {
      1024: {
        slidesPerView: 3,
      },
      768: {
        spaceBetween: 30,
        slidesPerView: 2,
      },
    },
    autoplay: {
      delay: 10000,
    },
  })

  advantagesSlider.init()

}
