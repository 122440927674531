import barba from '@barba/core'
import LazyLoad from 'vanilla-lazyload'
import { SCROLLFIRE, OBSERVER } from './main.js'
import { defaultTransition } from './transitions/default-transition.js'
import { backendKeyBind, ogContent, manageServicesBar, manageJobsBar, manageScrollToJobsForm, animButtons, initBackToTop, initScrollToContact, autoplayVideos, changeSelect, credit } from './functions/functions.js'
import { homeBanners, servicesBanners, advantagesBanners } from './functions/sliders.js'
import { dynamicPackage } from './functions/dynamic.js'
import { resetDropzone, formsPackage, clearInput } from './functions/form.js'
import { overlayDropdown, overlayShare, overlayQuote, overlaySearch, overlayMenu, overlayPopup } from './functions/overlays.js'
import { Accordions } from './classes/Accordions.js'
import Overlay from './vendor/overlay.js'
import { formContact, formQuote, formJobs, formSuccess } from './functions/validation.js'
import { masksPackage } from './functions/masks.js'
import { removeHoverOnMobile } from './functions/helper.js'
import { bannerAlerts } from './functions/banner-alert.js'

window.formSuccess = formSuccess
window.resetDropzone = resetDropzone

export const initBarba = () => {

  barba.hooks.beforeEnter(({ current }) => {
    if (current.container != null)
      current.container.remove()

    overlayDropdown()
    overlayShare()
    //overlayQuote()
    overlaySearch()
    overlayMenu()
    overlayPopup()

    bannerAlerts()

    backendKeyBind()
    clearInput()

    initBackToTop()

    SCROLLFIRE.init()
    removeHoverOnMobile()
  })

  barba.hooks.afterEnter(() => {
    animButtons()
  })

  barba.hooks.enter ((data) => {
    ogContent(data)
  })

  barba.hooks.afterLeave (() => {
    OBSERVER.allOff()
    OBSERVER.removeAll()
    Overlay.destroyAll()
    SCROLLFIRE.destroy()
    window['lazyload'].destroy()
  })

  barba.hooks.after(() => {
    gtag('event', 'page_view', { 'page_path': location.pathname + location.search })
  })

  barba.init({
    prefetchIgnore: true,
    cacheIgnore: true,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    transitions: [
      defaultTransition()
    ],

    views: [
      {
        namespace: 'standardView',
        beforeEnter() {
          new Accordions()
          formsPackage()
          masksPackage()
          dynamicPackage()
          manageServicesBar()
          manageJobsBar()
          servicesBanners() // Peut être mis en composante
          credit()

          initFormBuilder()
          recaptchaFormsCallback()

          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {}
      },
      {
        namespace: 'homeView',
        beforeEnter() {
          homeBanners()
          servicesBanners()
          advantagesBanners()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {}
      },
      {
        namespace: 'publicationsView',
        beforeEnter() {
          formsPackage()
          changeSelect('#catFilterSelect')
          credit()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {}
      },
      {
        namespace: 'quoteView',
        beforeEnter() {
          formsPackage()
          masksPackage()
          dynamicPackage()
          formQuote()
          initFormBuilder()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {}
      },
      {
        namespace: 'advicesView',
        beforeEnter() {
          new Accordions()
          formsPackage()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {}
      },
      {
        namespace: 'carreersView',
        beforeEnter() {
          new Accordions()
          manageJobsBar()
          autoplayVideos()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {}
      },
      {
        namespace: 'jobsView',
        beforeEnter() {
          dynamicPackage()
          formsPackage()
          masksPackage()
          formJobs()
          manageScrollToJobsForm()
          changeSelect('#catFilterSelect')
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {}
      },
      {
        namespace: 'contactView',
        beforeEnter() {
          new Accordions()
          formsPackage()
          masksPackage()
          formContact()
          initScrollToContact()
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {}
      },
      {
        namespace: 'searchView',
        beforeEnter() {
          window['lazyload'] = new LazyLoad()
        },
        afterLeave () {}
      },
    ]
  })
}
