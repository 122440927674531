import $ from 'jquery'
import { OBSERVER } from './../main.js'
import anime from 'animejs/lib/anime.es.js'
import { getElementOffset } from './helper.js'

// Fonction ajoutant l'événement 'click' qui appellera la fonction 'scrollToBlock'
export const clickToScrollToBlock = (options) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const onClick = () => scrollToBlock({
    scrollTo: options.scrollTo,
    duration: options.duration,
    easing: options.easing,
    offset: options.offset,
  })
  
  OBSERVER.add({
    name: 'scrollToBlock',
    event: 'click',
    target: options.selector,
    function: onClick
  })

  OBSERVER.on('scrollToBlock')
}


// Fonction exécutant l'animation du scroll vers son bloc
export const scrollToBlock = (options = {}) => {
  options.duration === undefined ? options.duration = 800              : options.duration
  options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
  options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
  options.offset   === undefined ? options.offset   = 0 : options.offset

  const scrollbar = window.document.scrollingElement || window.document.body || window.document.documentElement
  const element = typeof unknownElement === 'string' ? document.querySelector(options.scrollTo) : options.scrollTo

  const animation = anime.timeline({
    targets: scrollbar,
    duration: options.duration,
    easing: options.easing
  }).add({ scrollTop: getElementOffset({ element: element }).top + options.offset })
  
  return animation.finished
}


// Permet de créer un calendrier
export function calendar() {
  if (!document.querySelector('.calendar')){ //s'il n'y a pas d'events
    return
  }

  let eventsDatesList = document.querySelector('.calendar').dataset.list
  let currentDate = document.querySelector('.calendar').dataset.date

  $.fn.datepicker.dates['fr'] = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: 'Aujourd\'hui',
    clear: 'Clear',
    format: 'dd/mm/yyyy',
    titleFormat: 'MM yyyy',
    weekStart: 0
  }

  let datesEnabled = eventsDatesList.split(',')

  $('.calendar').datepicker({
    language: 'fr',
    maxViewMode: 0,
    format: 'yyyy-mm-dd',
    todayHighlight: true,
    beforeShowDay: function (date) { // Rendre seulement les dates de la liste d'événements disponibles
      let allDates = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
      if(datesEnabled.indexOf(allDates) != -1) return true; else return false
    }
  }).on('changeDate', function(e) {
    let theTimestamp =  Date.parse(e.date)/1000 //Le timestamp du date picker est en millisecondes, il faut le mettre en secondes
    $.request('onChangeDate', {
      data: {dateFilter: theTimestamp},
      // update: {'evenementlist::eventslist':'#eventsWrapper'},
      // complete: function (data) { data.then(function(data){ eventsLoaded(data) })},
      complete: function (data) { data.then(function(data){ Barba.Pjax.goTo('/evenements/1/' + data['date'])})},
    })
    //Fermer l'overlay quand on clique
    $('#overlayCalendar .wrapper a.close span.x').trigger('click')
  })

  $('.calendar').datepicker('update', currentDate)
}


// Copier du texte
export function copyTextToClipboard(text) {
  let pos = $(document).scrollTop()

  let textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) { console.log(err)}

  document.body.removeChild(textArea)
  $(document).scrollTop(pos)
}


// Ajoute un target blank sur tous les liens externes et PDF
// Empêche une transition si la destination est la page actuelle
export function forceBlankOnExterneAndPdfLinks() {
  let i
  const anchors = document.querySelectorAll('a[href]')
  for (i=0; i<anchors.length; i++) {
    if (anchors[i].target != '_blank' && anchors[i].href != 'javascript:;') {
      if (window.location.hostname !== anchors[i].hostname || anchors[i].href.match('\\.pdf$') || window.location.protocol !== anchors[i].protocol)
        anchors[i].setAttribute('target', '_blank')
    }
  }
}


// Ajoute les metas pour le contenu og
export function ogContent(data) {
  let ogUrl = data.next.html.match(/<meta.*property="og:url".*content="(.*)".*\/>/i)[1]
  let ogImage = data.next.html.match(/<meta.*property="og:image".*content="(.*)".*\/>/i)[1]
  let ogTitle = data.next.html.match(/<meta.*property="og:title".*content="(.*)".*\/>/i)[1]
  let ogDescription = data.next.html.match(/<meta.*property="og:description".*content="(.*)".*\/>/i)[1]
  document.querySelector('meta[property="og:url"]').setAttribute('content',ogUrl)
  document.querySelector('meta[property="og:image"]').setAttribute('content',ogImage)
  document.querySelector('meta[property="og:title"]').setAttribute('content',ogTitle)
  document.querySelector('meta[property="og:description"]').setAttribute('content',ogDescription)
}


// Permet d'aller au backend
export function backendKeyBind() {
  $('body').keydown(function (e) {
    let keycode = (e.keyCode ? e.keyCode : e.which)
    if ((e.ctrlKey && keycode == 13) || (e.metaKey && keycode == 13)) {
      location = '/administration'
      return false
    }
  })
}


export function animButtons() {
  var buttons = document.querySelectorAll('.primary-button,.secondary-button,.button--full-1,.button--full-2,.button--full-3,.button--empty-1,.js-button-animation'), i
  for (i = 0; i < buttons.length; ++i) {
    let node = document.createElement('span')
    node.classList.add('hover-anim')
    buttons[i].appendChild(node)
  }

  function mouseMoveOnButton(e) {
    let rect = e.target.getBoundingClientRect()
    let x = e.clientX - rect.left
    let y = e.clientY - rect.top

    let btnDecoration = e.target.querySelector('.hover-anim')
    if(typeof(btnDecoration) != 'undefined' && btnDecoration != null){
      btnDecoration.style.left = x+'px'
      btnDecoration.style.top = y+'px'
    }
  }
  
  OBSERVER.add({
    name: 'animButtonsEnter',
    event: 'mouseenter',
    target: buttons,
    function: mouseMoveOnButton
  })
  OBSERVER.on('animButtonsEnter')

  OBSERVER.add({
    name: 'animButtonsExit',
    event: 'mouseleave',
    target: buttons,
    function: mouseMoveOnButton
  })
  OBSERVER.on('animButtonsExit')

}

export function manageScrollToJobsForm() {
  clickToScrollToBlock({ selector: '.js-scroll-to-jobs-form', scrollTo: '.js-jobs-form', offset: 0 })
}



export function manageServicesBar() {
  var hasForm = document.querySelector('.layout__form') !== null;
  if(hasForm) {
    clickToScrollToBlock({ selector: '.js-scroll-to-quote-form', scrollTo: '.layout__form', offset: -80 })
  } else {
    var hasButton = document.querySelector('.js-scroll-to-quote-form') !== null;
    if(hasButton) {
      document.querySelector('.js-scroll-to-quote-form').style.display = 'none'
    }
  }

  OBSERVER.add({
    name: 'manageServicesBarOpacity',
    event: 'scroll',
    root: document,
    function: manageServicesBarOpacity
  })
  manageServicesBarOpacity()

  OBSERVER.on('manageServicesBarOpacity')
}

function manageServicesBarOpacity() {
  let element = document.querySelector('.quote-cta')
  let elDistanceToTop = (window.pageYOffset + element.getBoundingClientRect().top) - window.innerHeight
  if(window.scrollY > elDistanceToTop) {
    document.querySelector('html').classList.add('hide-quote-cta')
  }else{
    document.querySelector('html').classList.remove('hide-quote-cta')
  }
}



export function manageJobsBar() {
  clickToScrollToBlock({ selector: '.js-scroll-jobs-list', scrollTo: '.layout__publications', offset: -60 })
  clickToScrollToBlock({ selector: '.js-scroll-jobs-introduction', scrollTo: '.layout__dynamic', offset: -60 })
  //clickToScrollToBlock({ selector: '.js-scroll-jobs-mission', scrollTo: '.layout__carreer-cta .carreer-cta:nth-child(1)', offset: 0 })
  clickToScrollToBlock({ selector: '.js-scroll-jobs-advantages', scrollTo: '.layout__carreer-cta .carreer-cta:nth-child(1)', offset: 0 })
}

export const initBackToTop = (options = {}) => {
  options.root === undefined ? options.root  = document : options.root
  
  OBSERVER.add({
    name: 'manageBackToTop',
    event: 'scroll',
    root: options.root,
    function: manageBackToTop
  })

  clickToScrollToBlock({ selector: '.js-back-to-top', scrollTo: 'header' })
  manageBackToTop()

  OBSERVER.on('manageBackToTop')
}

function manageBackToTop(){
  let headerVisible = document.documentElement.scrollTop <= 600
  if(!headerVisible) {
    document.querySelector('html').classList.add('show-back-to-top')
  } else {
    document.querySelector('html').classList.remove('show-back-to-top')
  }
}



export const initScrollToContact = (options = {}) => {
  options.root === undefined ? options.root  = document : options.root
  
  OBSERVER.add({
    name: 'manageScrollToContact',
    event: 'scroll',
    root: options.root,
    function: manageScrollToContact
  })

  clickToScrollToBlock({ selector: '.js-scroll-to-contact', scrollTo: '.js-contact', offset: -80 })
  manageScrollToContact()

  OBSERVER.on('manageScrollToContact')
}

function manageScrollToContact() {
  let element = document.querySelector('.contact-top__side')
  let elDistanceToTop = (window.pageYOffset + element.getBoundingClientRect().top) - window.innerHeight
  if(window.scrollY > elDistanceToTop) {
    document.querySelector('html').classList.add('hide-contact-button')
  }else{
    document.querySelector('html').classList.remove('hide-contact-button')
  }
}

export function autoplayVideos() {
  let vid = document.querySelector('.js-video-autoplay')
  if(vid){
    if(window.innerWidth >= 768)
      vid.play()
  }
}

export function changeSelect(select) {
  $('.dropdown-option').on('click', function() {
    $(select).trigger('change')
  })
}

// Fonction permettant d'ouvrir le crédit ou de la fermer
export function credit() {
  if (document.querySelector('.js-credit-toggle')){
    const creditToggle = document.querySelector('.js-credit-toggle')

    creditToggle.onclick = function(){
      creditToggle.classList.toggle('active')
    }
  }
}