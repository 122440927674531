import '../scss/style.scss';

import $ from 'jquery'
window.jQuery = $
window.$ = $
import '@fancyapps/fancybox/dist/jquery.fancybox.js'
import 'bootstrap-datepicker'
import 'jquery-validation'
import 'jquery-mask-plugin'
import 'jquery-validation/dist/additional-methods.js'

import Observer from './vendor/observer.min.js'
import Scrollfire from './vendor/scrollfire.min.js'
import { initBarba } from './barba'

export const OBSERVER = Observer.getInstance()
export const SCROLLFIRE = Scrollfire.getInstance()

addEventListener('DOMContentLoaded', () => {
  initBarba()
})
